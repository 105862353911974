// useScrollToSavedSection.js

import { useEffect } from "react";

const smoothScrollTo = (target, duration) => {
  const targetPosition = target.getBoundingClientRect().top + window.pageYOffset;
  const startPosition = window.pageYOffset;
  const distance = targetPosition - startPosition;
  let startTime = null;

  const animation = (currentTime) => {
    if (startTime === null) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const run = ease(timeElapsed, startPosition, distance, duration);
    window.scrollTo(0, run);
    if (timeElapsed < duration) {
      requestAnimationFrame(animation);
    }
  };

  const ease = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  requestAnimationFrame(animation);
};

const useScrollToSavedSection = () => {
  useEffect(() => {
    const savedSection = localStorage.getItem("savedSection");
    if (!savedSection) return;

    const id = savedSection.replace("#", "");
    const scrollToElement = (retryCount = 0) => {
      const element = document.getElementById(id);

      if (element) {
        // console.log(`Scrolling to saved section: ${id}`);

        setTimeout(() => {
          // 1) Your existing smooth scroll
          smoothScrollTo(element, 2000);

          // 2) After main smooth scroll finishes, do final immediate check
          setTimeout(() => {
            // *** Start the "stabilizer" interval ***
            let lastTop = null;
            let attempts = 0;
            const maxAttempts = 30; 
            const interval = setInterval(() => {
              attempts++;
              const { top, bottom } = element.getBoundingClientRect();
              const isFullyVisible =
                top >= 0 && bottom <= (window.innerHeight || document.documentElement.clientHeight);

              // If we haven't measured lastTop yet, set it
              if (lastTop === null) {
                lastTop = top;
              }

              // If the element is not fully visible or the top changed significantly
              if (!isFullyVisible || Math.abs(top - lastTop) > 5) {
                // console.log(`Adjusting scroll for section: ${id}... (attempt ${attempts})`);
                element.scrollIntoView({ behavior: "smooth", block: "center" });
                lastTop = top;
              }

              // Stop if attempts are done or if it looks stable
              if (attempts >= maxAttempts || isFullyVisible) {
                clearInterval(interval);
                // removeItem once we're done
                localStorage.removeItem("savedSection");
                // console.log(`Done stabilizing scroll for #${id}`);
              }
            }, 100); // check every 100ms
            // *** End stabilizer interval ***

          }, 1000); // wait until smoothScroll likely ends
        }, 200); 
      } else if (retryCount < 10) {
        // console.warn(`Retrying scroll for section: ${id} (${retryCount + 1}/10)`);
        setTimeout(() => scrollToElement(retryCount + 1), 200);
      } else {
        // console.error(`Element with ID "${id}" not found or not visible after 10 retries.`);
      }
    };

    const onPageLoad = () => {
      if (document.readyState === "complete") {
        scrollToElement();
      } else {
        window.addEventListener("load", () => scrollToElement(), { once: true });
      }
    };

    onPageLoad();
  }, []);
};

export default useScrollToSavedSection;
