import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../../content/misc/Headings.js";
import { SectionDescription } from "../../content/misc/Typography.js";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import client from 'sanityClient';
import { useLanguage } from "../../helpers/languageSelector.js"; // Import the LanguageSelector context


const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-6 md:py-8`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const InsuranceCards = ({ 
  cards = null, 
  heading = "Insurance Services", 
  subheading = "Support", 
  description = "For example, you can decide for yourself whether you only want travel insurance, cancellation insurance or a combination of the two. You can also choose from the Basic, Comfort and Optimal variants. This allows you to determine how well you are insured. Finally, you can expand your insurance with various coverage, so that it fully fits your surfing trip." 
}) => {
  const { currentLanguage } = useLanguage();
  const [data, setData] = useState({
    subheading2: '',
    heading2: '',
    description2: '',
    cards: []
  });

  useEffect(() => {
    client.fetch(`*[_type == "insurance" && language == "${currentLanguage}"]`)
      .then((fetchedData) => {
        if (fetchedData.length > 0) {
          setData(fetchedData[0]);
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [currentLanguage]);

  const defaultCards = [
    {
      title: "Submit a claim",
      description: "If you want to file a claim with Europeesche Verzekeringen, you can do this in 2 ways:\n– Online via the claims forum\n– By telephone during office hours via telephone number 020-651 52 53\n\nWhen reporting your claim, keep the invoices, warranty certificates, statements and other evidence at hand. The more completely you describe your damage and add evidence, the faster Europeesche Verzekeringen can settle your claim."
    },
    {
      title: "Help in your pocket app",
      description: "The free Help in Pocket app from Europeesche Verzekeringen offers you important medical information about your (future) holiday destination. The app is equipped with an extensive medical source of information and a medical dictionary in 9 languages. You can also find the numbers of all Dutch emergency centers in this app."
    },
    {
      title: "Problems on holiday",
      description: "You go on holiday to enjoy yourself. If something goes wrong, you can call the Europeesche Helpline worldwide, day and night. The employees of the Europeesche Helpline will answer your questions in the event of breakdowns, accidents, illness and emergencies. If you need a doctor, they will refer you to a reliable specialist or a good hospital near you. If necessary, they immediately call the SOS International emergency center for help."
    },
    {
      title: "Europeesche Helpline",
      description: "The Europeesche Helpline can be reached via +31-20 65 15 777 \n Have your booking number and the telephone number where you can be reached at hand when you contact the Europeesche Helpline. We recommend that you save the European Helpline number in your mobile phone. Then you always have the number at hand in case of an emergency."
    },
    {
      title: "Tips in case of damage, loss or theft",
      description: "If your luggage is damaged or lost during the holiday, it is important that you have a statement drawn up by our tour guide. It is also important that you always report loss or theft to the local police. Always ask for written proof of this.\n\nKeep your damaged items, invoices and statements in a safe place. It is possible that Europeesche Verzekeringen will ask you to send this at a later stage."
    }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        {data.subheading2 && <Subheading>{data.subheading2}</Subheading>}
        <Heading>{data.heading2}</Heading>
        {data.description2 && <Description>{data.description2}</Description>}
        <VerticalSpacer />
        {(data.cards && data.cards.length > 0 ? data.cards : defaultCards).map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      {/* <DecoratorBlob /> */}
    </Container>
  );
};

export default InsuranceCards;
