import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "../misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "../misc/Buttons";
import { ReactComponent as Persons } from "feather-icons/dist/icons/users.svg";
import { ReactComponent as Star } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import handleNavClick from "../../helpers/useSmoothScroll";
import client from 'sanityClient';
import { useLanguage } from "../../helpers/languageSelector"; // Make sure this is correctly imported
import { urlFor } from 'sanityImageUrl'; // Ensure this is correctly set up for your project
import BackupImage from '../../images/surflife/sl16.jpg'

const Container = tw.div`relative mt-1`;
const Content = tw.div`max-w-screen-xl mx-auto py-4`;

const HeadingWithControl = tw.div` flex flex-col items-center justify-center lg:justify-center`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex mt-4 items-center justify-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-4`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
    opacity: 0; // Hide all slides by default
    transition: opacity 0.3s ease-in-out;
  }
  .slick-slide.slick-active {
    opacity: 1; // Show only active slides (up to 3)
  }
`;

const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-4xl relative focus:outline-none bg-white`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl sm:rounded-tr-4xl`
]);

const TextInfo = tw.div`py-2 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const Description = tw.p`text-base leading-snug mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-row flex-wrap sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded-br-2xl rounded-bl-2xl py-3 sm:py-6`;

export default () => {
  const [sliderRef, setSliderRef] = useState(null);
  const { currentLanguage } = useLanguage();
  const [cards, setCards] = useState([]);
  const [content, setContent] = useState({ heading: '' });
  const [update, setUpdate] = useState(false);
  const [isFirstCard, setIsFirstCard] = useState(true);
  const [isLastCard, setIsLastCard] = useState(false);

  useEffect(() => {
    // Fetch content based on the current language
    client.fetch(`*[_type == "cards" && language == "${currentLanguage}"][0]`)
      .then((data) => {
        setContent({
          heading: data.heading,
          buttonText: data.buttonText || 'Book Now', // Set default if not provided
        });
        if (data && data.tents) {
          const fetchedCards = data.tents.map((item) => ({
            imageSrc: item.image ? urlFor(item.image).url() : BackupImage,
            title: item.title,
            description: item.description,
            bookLink: item.bookLink,
            highlight: item.highlight,
            persons: item.persons,
          }));
          setCards(fetchedCards);
        }
        setUpdate(prev => !prev); // Trigger a re-render
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [currentLanguage]);

  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    infinite: false,
    centerMode: false,
    focusOnSelect: false,
    responsive: [
      { breakpoint: 1280, settings: { slidesToShow: 2 } },
      { breakpoint: 900, settings: { slidesToShow: 1 } },
    ],
    afterChange: (current) => {
      setIsFirstCard(current === 0);
      setIsLastCard(current >= cards.length - sliderSettings.slidesToShow);
    },
  };

  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>{content.heading}</Heading>
        </HeadingWithControl>
        <CardSlider key={update} ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage alt={card.title} imageSrc={card.imageSrc} />
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                </TitleReviewContainer>
                <SecondaryInfoContainer>
                  <IconWithText>
                    <IconContainer>
                      <Star />
                    </IconContainer>
                    <Text>{card.highlight}</Text>
                  </IconWithText>
                  <IconWithText>
                    <IconContainer>
                      <Persons />
                    </IconContainer>
                    <Text>{card.persons}</Text>
                  </IconWithText>
                </SecondaryInfoContainer>
                <Description>{card.description}</Description>
              </TextInfo>
              {/* <PrimaryButton href={content.bookLink} target="_blank" rel="noopener noreferrer">
                {content.buttonText}
              </PrimaryButton> */}
            </Card>
          ))}
        </CardSlider>
        <Controls>
          <PrevButton
            onClick={() => sliderRef?.slickPrev()}
            disabled={isFirstCard}
            style={{ opacity: isFirstCard ? 0.5 : 1 }}
          >
            <ChevronLeftIcon />
          </PrevButton>
          <NextButton
            onClick={() => sliderRef?.slickNext()}
            disabled={isLastCard}
            style={{ opacity: isLastCard ? 0.5 : 1 }}
          >
            <ChevronRightIcon />
          </NextButton>
        </Controls>
      </Content>
    </Container>
  );
};
