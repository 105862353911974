import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled, { css } from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings.js";
import { SectionDescription } from "../misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "../misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "../misc/Layouts.js";
import Modal from '../../helpers/Modal'; // Import the new Modal component
import client from 'sanityClient';
import { useLanguage } from "../../helpers/languageSelector"; // Import the LanguageSelector context

const HeaderContainer = tw.div`mt-1 w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-1`;
const Heading = tw(SectionHeading)`w-full -mt-4`;
const Description = tw(SectionDescription)`mt-1 w-full text-center`;
const PlansContainer = styled.div`
  ${tw`flex justify-center items-stretch gap-2`} /* Default styles */
  
  @media (max-width: 506px) {
    ${tw`flex flex-col items-center`} /* Stack elements vertically */
  }
`;

// Conditionally apply styles based on whether the plan is featured
const Plan = styled.div`
  ${tw`w-full max-w-sm mt-4 lg:mr-8 lg:last:mr-0 text-center px-2 rounded-lg shadow relative pt-2 text-gray-900 bg-alt flex flex-col`}

  ${({ featured }) =>
    featured &&
    css`
      background: linear-gradient(135deg, rgba(77, 116, 120, 1) 0%, rgba(133, 160, 164, 1) 100%);
      ${tw`bg-primary-500 text-gray-100`}

      .feature:not(.mainFeature) {
        ${tw`text-gray-300`}
      }

      .duration {
        ${tw`text-gray-200`}
      }

      ${BuyNowButton} {
        ${tw`bg-gray-100 text-primary-500 hocus:bg-gray-300 hocus:text-primary-800`}
      }
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-8`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest`}
  }
`;

const PlanFeatures = styled.div`
  ${tw`flex flex-col px-8 py-8 flex-1 border-t-4 border-gray-300`}
  .feature {
    ${tw`mt-5 first:mt-0 font-medium`}
  }
  .mainFeature {
    ${tw`text-xl font-bold tracking-wide`}
  }
`;

const PlanAction = tw.div`px-4 sm:px-8 xl:px-16 py-8`;
const BuyNowButton = styled(PrimaryButtonBase).attrs({ as: "button" })`
  ${tw`rounded-full uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

export default () => {
  const { currentLanguage } = useLanguage(); // Get the current language from the context
  const [plans, setPlans] = useState([]);
  const [header, setHeader] = useState({ subheading: "", heading: "", description: "" });
  const [primaryButtonText, setPrimaryButtonText] = useState("Book");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlanName, setSelectedPlanName] = useState(null);

  useEffect(() => {
    client.fetch(`*[_type == "plans" && language == "${currentLanguage}"][0]`)
      .then((data) => {
        setPlans(data.plans);
        setHeader({
          subheading: data.subheading,
          heading: data.heading,
          description: data.description
        });
        setPrimaryButtonText(data.primaryButtonText || "Book");
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [currentLanguage]);

  const handleOpenModal = (planModal) => {
    setSelectedPlanName(planModal); // Set the selected plan modal
    setIsModalOpen(true);
  };

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {header.subheading && <Subheading>{header.subheading}</Subheading>}
          <Heading>{header.heading}</Heading>
          {header.description && <Description>{header.description}</Description>}
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader>
                <span className="name">{plan.name}</span>
                <span className="duration">{plan.duration}</span>
                <span className="price">{plan.price}</span>
              </PlanHeader>
              <PlanFeatures>
                <span className="feature mainFeature">{plan.mainFeature}</span>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature">{feature}</span>
                ))}
              </PlanFeatures>
              <PlanAction>
                <BuyNowButton onClick={() => handleOpenModal(plan.modal)}>
                  {primaryButtonText}
                </BuyNowButton>
              </PlanAction>
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} planName={selectedPlanName} />
    </Container>
  );
};
