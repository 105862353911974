import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import client from 'sanityClient';

const ModalContainer = styled.div`
  ${tw`fixed inset-0 flex items-center justify-center z-50`}
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
  ${tw`bg-white rounded-lg relative`}
  max-width: 90vw;
  max-height: 90vh;
  width: 100%;
  overflow-y: auto;
  padding: 2rem;

  @media (min-width: 1024px) {
    max-width: 75vw;
  }

  @media (min-width: 1440px) {
    max-width: 60vw;
  }
`;

const CloseButton = styled.button`
  ${tw`absolute top-0 right-0 mt-2 mr-2 p-2 text-gray-700 hover:text-black`}
`;

const TitleWrapper = styled.div`
  ${tw`text-center mb-4`}
  .title-subtitle {
    ${tw`text-lg font-light`}
  }
  .title {
    ${tw`text-2xl font-bold my-2`}
  }
  .title-after {
    ${tw`text-sm mt-1`}
  }
  .liner-container {
    ${tw`flex items-center justify-center mt-2`}
    .left-line, .right-line {
      ${tw`w-1/4 h-px bg-gray-300 mx-2`}
    }
  }
`;

const TableContainer = styled.div`
  ${tw`overflow-x-auto my-6`}
  table {
    ${tw`w-full border-collapse`}
    
    th, td {
      ${tw`px-4 py-2 text-center`}
      border-left: none;
      border-right: none;
    }

    th {
      ${tw`bg-gray-100 font-semibold border-b`}
    }

    tbody tr:first-of-type td {
      ${tw`border-t-0`}
    }

    tbody tr + tr td {
      ${tw`border-t border-b`}
    }

    a {
      ${tw`font-bold underline`}
    }
  }
`;

const List = styled.ul`
  ${tw`list-disc list-inside mt-4`}
`;

const Paragraph = styled.p`
  ${tw`text-center text-sm mt-4`}
`;

const OptionalSectionTitle = styled.h6`
  ${tw`font-bold mt-6 mb-2`}
`;

const Modal = ({ isOpen, onClose, planName }) => {
  const [modalContent, setModalContent] = useState(null);

  const handleOutsideClick = (e) => {
    if (e.target.id === 'modal-overlay') {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen && planName) {
      client
        .fetch(`*[_type == "modal" && planName == $planName][0]`, { planName })
        .then((data) => {
          setModalContent(data);
        })
        .catch((error) => console.error('Error fetching modal content:', error));
    }
  }, [isOpen, planName]);
  
  

  if (!isOpen || !modalContent) return null;

  const transposedColumns = modalContent.table
    ? modalContent.table.rows[0].cells.map((_, colIndex) => 
        modalContent.table.rows.map((row) => row.cells[colIndex])
      )
    : [];

  return (
    <ModalContainer id="modal-overlay" onClick={handleOutsideClick}>
      <ModalContent>
        <CloseButton onClick={onClose}>✖</CloseButton>

        <TitleWrapper>
          <span className="title-subtitle">{modalContent.subtitle || "CHOOSE YOUR WEEK"}</span>
          <div className="liner-container">
            <span className="left-line"></span>
            <h4 className="title">{modalContent.title || "Price overview Family Tipi Tent"}</h4>
            <span className="right-line"></span>
          </div>
          <span className="title-after">{modalContent.afterTitle || "ALL PRICES AS DESCRIBED IN PRICE OVERVIEW"}</span>
        </TitleWrapper>

        {modalContent.table && (
          <TableContainer>
            <table>
              <thead>
                <tr>
                  {modalContent.table.columns.map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {transposedColumns.map((column, colIndex) => (
                  <tr key={colIndex}>
                    {column.map((cell, rowIndex) => (
                      <td key={rowIndex}>
                        {cell.link ? (
                          <a href={cell.link} target="_blank" rel="noopener noreferrer">
                            {cell.text}
                          </a>
                        ) : (
                          cell.text
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        )}

        {modalContent.additionalText && (
          <Paragraph>{modalContent.additionalText}</Paragraph>
        )}

        {modalContent.optionalSections?.map((section, index) => (
          <div key={index}>
            <OptionalSectionTitle>{section.title}</OptionalSectionTitle>
            <List>
              {section.items.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </List>
          </div>
        ))}
      </ModalContent>
    </ModalContainer>
  );
};

export default Modal;


