import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../misc/Buttons.js";
// Removed the import for EmailIllustrationSrc
import client from 'sanityClient';
import { urlFor } from 'sanityImageUrl';
import { useLanguage } from "../../helpers/languageSelector"; // Import the LanguageSelector context
import BackupImage from '../../images/surflife/sl16.jpg'


const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-6 md:py-8`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-2 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const ContactInfo = tw.div`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const ContactItem = styled.p`
  ${tw`mt-2`}
  a {
    ${tw`font-bold text-primary-500`}
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`
const Input = tw.input`border-2 px-5 py-3 rounded focus:outline-none font-medium transition duration-300 hocus:border-primary-500`

const SubmitButton = tw(PrimaryButtonBase)`inline-block lg:ml-6 mt-6 lg:mt-0`

const QuoteContainer = tw.div`relative mt-10 lg:mt-20`;
const Quote = tw.blockquote`text-center lg:text-left text-sm sm:text-lg lg:text-xl xl:text-2xl`;
const CustomerInfo = tw.div`mt-6 flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerProfilePicture = tw.img`rounded-full w-48 h-48`;
const CustomerTextInfo = tw.div`text-center lg:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerName = tw.h5`font-semibold text-xl lg:text-2xl xl:text-3xl text-primary-500`;
const CustomerTitle = tw.p`font-medium text-secondary-100`;

const MapContainer = styled.div`
  ${tw`relative w-full h-64 sm:h-96 rounded-lg overflow-hidden mt-8 md:mt-0`}
  iframe {
    ${tw`w-full h-full border-none`}
  }
`;

export default ({ textOnLeft = true }) => {
  const { currentLanguage } = useLanguage(); // Get the current language from the context
  const [contactInfo, setContactInfo] = useState({
    subheading: '',
    heading: '',
    description: '',
    emailLabel: '',
    email: '',
    phoneLabel: '',
    phoneFront: '',
    phoneBack: '',
    whatsappLabel: '',
    whatsappFront: '',
    whatsappBack: '',
    image: null,
    testimonials: []
  });

  useEffect(() => {
    client.fetch(`*[_type == "contact" && language == "${currentLanguage}"][0]`)
      .then((data) => {
        setContactInfo(data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [currentLanguage]);

  return (
    <Container>
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {contactInfo.subheading && <Subheading>{contactInfo.subheading}</Subheading>}
            <Heading>{contactInfo.heading}</Heading>
            <ContactInfo>
              <ContactItem>
                {contactInfo.emailLabel}: <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
              </ContactItem>
              <ContactItem>
                {contactInfo.phoneLabel}: <br /> <a href={`tel:${contactInfo.phoneBack}`}>{contactInfo.phoneFront} </a>
              </ContactItem>
              <ContactItem>
                {contactInfo.whatsappLabel}: <a href={`tel:${contactInfo.whatsappBack}`} target="_blank" rel="noopener noreferrer"> <br /> {contactInfo.whatsappFront} </a>
              </ContactItem>
            </ContactInfo>
            <Description>{contactInfo.description}</Description>
          </TextContent>
        </TextColumn>
        <Column>
          {contactInfo.testimonials.map((testimonial, index) => (
            <QuoteContainer key={index}>
              {/* <Quote>
                {testimonial.quote}
              </Quote> */}
              <CustomerInfo>
              <CustomerProfilePicture
                  src={testimonial.profileImageSrc ? urlFor(testimonial.profileImageSrc).url() : BackupImage}
                  alt={testimonial.customerName}
                />
                <CustomerTextInfo>
                  <CustomerName>{testimonial.customerName}</CustomerName>
                  <CustomerTitle>{testimonial.customerTitle}</CustomerTitle>
                </CustomerTextInfo>
              </CustomerInfo>
            </QuoteContainer>
          ))}
        </Column>
        <Column>
          {contactInfo.image && (
            <CustomerProfilePicture
            src={contactInfo.image ? urlFor(contactInfo.image).url() : BackupImage}
            alt="Contact"
          />
          )}
          <MapContainer>
            <iframe
              src="https://www.openstreetmap.org/export/embed.html?bbox=-1.4,44.12,-1.12,45.18&layer=mapnik&marker=44.144444,-1.24"
              title="Bias Plage Map"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </MapContainer>
        </Column>
      </TwoColumn>
    </Container>
  );
};
