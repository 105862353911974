import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useLanguage } from "../helpers/languageSelector";
import tw, { styled } from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Menu from "content/components/menu.js";
import MainFeature from "content/components/mainFeature.js";
import Highlight from "content/components/highlight.js";
import Features from "content/components/features.js";
import Plans from "content/components/plans.js";
import Cards from "content/components/cards.js";
import Testimonials from "content/components/testimonials.js";
import Team from "content/components/team.js";
import Blog from "content/components/blog.js";
import FAQ from "content/components/faq.js";
import Socials from "content/components/socials.js";
import Contact from "content/components/contact.js";
import Footer from "content/components/footer.js";
import Cookies from "content/components/cookies.js";
import backgroundImage from "images/surflife/surflife_back.png"; // Background image

const BackgroundContainer = styled.div`
  ${tw`w-full relative`}
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${backgroundImage});
    background-size: 100%;
    background-repeat: repeat-y;
    background-position: top center;
    opacity: 0.1;
    z-index: -1;
  }
`;

const SurfLifeLandingPage = () => {
  const { currentLanguage, setCurrentLanguage } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;

    // If the user visits a language-specific route, set the language manually
    if (pathname === "/nl" || pathname === "/en" || pathname === "/de") {
      const selectedLanguage = pathname.replace("/", "");
      setCurrentLanguage(selectedLanguage);
    } else if (pathname === "/") {
      // Redirect based on the detected or stored language
      switch (currentLanguage) {
        case "nl":
          navigate("/nl", { replace: true });
          break;
        case "de":
          navigate("/de", { replace: true });
          break;
        case "en":
          navigate("/en", { replace: true });
          break;
        default:
          navigate("/en", { replace: true }); // Default fallback
      }
    }
  }, [currentLanguage, location.pathname, navigate, setCurrentLanguage]);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language") || "nl"; // Default to 'nl'
    if (location.pathname === "/") {
      navigate(`/${storedLanguage}`, { replace: true });
    }
  }, [location.pathname, navigate]);
  

  return (
    <AnimationRevealPage>
      <BackgroundContainer>
        <div id="hero">
          <Menu />
        </div>
        <div id="surf-camp">
          <MainFeature />
        </div>
        <div id="highlight">
          <Highlight />
        </div>
        <div id="included">
          <Features />
        </div>
        <div id="pricing">
          <Plans />
        </div>
        <div id="stay">
          <Cards />
        </div>
        <div id="testimonials">
          <Testimonials />
        </div>
        <div id="blog">
          <Blog />
        </div>
        <div id="crew">
          <Team />
        </div>
        <div id="faq">
          <FAQ imageContain={true} imageShadow={false} subheading="FAQs" />
        </div>
        <div id="socials">
          <Socials />
        </div>
        <div id="contact">
          <Contact />
        </div>
        <Footer />
        <Cookies />
      </BackgroundContainer>
    </AnimationRevealPage>
  );
};

export default SurfLifeLandingPage;
