import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import client from 'sanityClient';
import { urlFor } from 'sanityImageUrl';
import { useLanguage } from "../../helpers/languageSelector"; // Import the LanguageSelector context
import { PortableText } from '@portabletext/react';
import BackupImage from '../../images/surflife/sl16.jpg'


const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-8`;

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-6 md:py-8`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded-lg bg-cover bg-center h-full`,
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`-mt-4 lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto bg-semi lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-300`;

const TabsControlContainer = styled.div`
  ${tw`flex justify-start mt-4`}
`;
const TabsControl = styled.div`
  ${tw`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none`}
  width: fit-content;
`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
`;

const FAQSContainer = tw.dl`mt-8`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

// Portable Text components configuration
const portableTextComponents = {
  block: {
    normal: ({ children }) => <p>{children}</p>,
    h1: ({ children }) => <h1 tw="text-2xl font-bold">{children}</h1>,
    h2: ({ children }) => <h2 tw="text-xl font-bold">{children}</h2>,
    h3: ({ children }) => <h3 tw="text-lg font-bold">{children}</h3>,
    blockquote: ({ children }) => <blockquote tw="border-l-4 border-gray-300 pl-4 italic">{children}</blockquote>,
  },
  list: {
    bullet: ({ children }) => <ul tw="list-disc pl-5">{children}</ul>,
    number: ({ children }) => <ol tw="list-decimal pl-5">{children}</ol>,
  },
  marks: {
    strong: ({ children }) => <strong tw="font-bold">{children}</strong>,
    em: ({ children }) => <em tw="italic">{children}</em>,
  },
};

export default ({
  textOnLeft = false,
  imageContain = false,
  imageShadow = true,
}) => {
  const { currentLanguage } = useLanguage();
  const [faqData, setFaqData] = useState({
    subheading: '',
    heading: '',
    description: '',
    image: null,
    faqCategories: []
  });
  const [activeTab, setActiveTab] = useState('');
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  useEffect(() => {
    client.fetch(`*[_type == "faq" && language == "${currentLanguage}"][0]`)
      .then((data) => {
        setFaqData(data);
        setActiveTab(data.faqCategories[0]?.category);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [currentLanguage]);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <Content>
        <TwoColumn>
          <ImageColumn>
            {faqData.image && <Image alt={faqData.heading} imageContain={imageContain} imageShadow={imageShadow} imageSrc={faqData.image ? urlFor(faqData.image).url() : BackupImage} />}
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <FAQContent>
              {faqData.subheading && <Subheading>{faqData.subheading}</Subheading>}
              <Heading>{faqData.heading}</Heading>
              <Description>{faqData.description}</Description>
              <TabsControlContainer>
                <TabsControl>
                  {faqData.faqCategories.map((tab, index) => (
                    <TabControl key={index} active={activeTab === tab.category} onClick={() => setActiveTab(tab.category)}>
                      {tab.category}
                    </TabControl>
                  ))}
                </TabsControl>
              </TabsControlContainer>
              <FAQSContainer>
                {faqData.faqCategories.find(cat => cat.category === activeTab)?.faqs.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex === index ? "open" : "collapsed"}
                      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      <PortableText value={faq.answer} components={portableTextComponents} />
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </TextColumn>
        </TwoColumn>
      </Content>
    </Container>
  );
};
