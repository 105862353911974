import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "content/components/menu.js";
import Insurance1 from "content/components/options.js"
import Insurance2 from "demos/faqs/collum.js"
import ContactUsForm from "content/components/contact.js";
import Footer from "content/components/footer.js";




export default () => (
  <AnimationRevealPage>
    <div id="hero">
      <Hero />
    </div>
    {/* <div id="Insurance1">
      <Insurance1 />
    </div> */}
    <div id="Insurance2">
      <Insurance2 />
    </div>
    <div id="contact">
      <ContactUsForm />
    </div>
    <Footer />
  </AnimationRevealPage>
);
