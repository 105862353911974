import React, { useState, useEffect, useCallback } from "react";
import { Container, ContentWithPaddingXl } from "../misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { motion } from "framer-motion";
import { css } from "styled-components/macro";
import { SectionHeading } from "../misc/Headings";
import client from 'sanityClient';
import { useLanguage } from "../../helpers/languageSelector";
import { urlFor } from 'sanityImageUrl';
import BackupImage from '../../images/surflife/sl16.jpg';
import throttle from "lodash.throttle";

const HeadingContainer = tw.div`text-center -mt-2 md:-mt-8 lg:-mt-8`;
const Heading = tw(SectionHeading)``;

const Posts = tw.div`-mt-4 flex flex-wrap -mr-3 relative`;
const Post = tw.a`flex flex-col h-full bg-alt rounded cursor-pointer`;
const PostImage = styled(motion.div)`
  ${props => css`
    background-image: url("${props.imageSrc}");
    background-repeat: no-repeat;
    background-size: cover;
  `}
  ${tw`h-full bg-center rounded-t`}
`;

const PostText = styled.div`
  ${tw`flex flex-col px-6 py-4`}
  ${({ isSmallPost }) =>
    isSmallPost &&
    css`
      ${tw`py-2`}
    `}
`;

const PostTitle = styled.h6`
  ${tw`font-bold group-hocus:text-primary-500 transition duration-300`}
  ${({ isSmallPost }) =>
    isSmallPost &&
    css`
      ${tw`text-sm`}
    `}
`;

const PostDescription = tw.p``;
const AuthorInfo = tw.div`flex`;
const AuthorTextInfo = tw.div`text-xs text-gray-600`;
const AuthorName = tw.div`font-semibold mt-2`;

const PostContainer = styled.div`
  ${tw`relative z-20 mt-5 sm:pt-3 pr-3 w-1/2 sm:w-1/2 lg:w-1/3 sm:max-w-none sm:mx-0`}
  transition: width 0.5s ease-in-out;

  ${props => props.featured && css`
    ${tw`w-full sm:w-full lg:w-2/3`}
    ${Post} {
      ${tw`sm:flex-row items-center sm:pr-3`}
    }
    ${PostImage} {
      ${tw`sm:h-80 h-64 w-full sm:w-full rounded-t sm:rounded-t-none sm:rounded-l`}
    }
    ${PostText} {
      ${tw`pl-8 pr-5`}
    }
    ${PostTitle} {
      ${tw`text-2xl`}
    }
    ${PostDescription} {
      ${tw`mt-4 text-sm max-w-128 font-semibold text-gray-600 leading-relaxed`}
    }
    ${AuthorInfo} {
      ${tw`mt-8 flex items-center`}
    }
    ${AuthorName} {
      ${tw`mt-0 font-bold text-gray-700 text-sm`}
    }
  `}
  
  ${props => props.isSmallPost && css`
    ${tw`sm:w-1/2 md:w-1/4 lg:w-1/6`}
    ${Post} {
      ${tw`h-40`}
    }
    ${PostImage} {
      ${tw`h-2/3`} /* Ensure image is visible for small posts */
    }
    ${PostText} {
      ${tw`h-1/3`}
    }
  `}
`;

export default ({
  subheading = "",
  heading = "Things to do",
  description = ""
}) => {
  const { currentLanguage } = useLanguage();
  const [content, setContent] = useState({
    heading: '',
    subheading: '',
    description: '',
    posts: []
  });

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Optimize the resize event handler with throttling
  useEffect(() => {
    const handleResize = throttle(() => setScreenWidth(window.innerWidth), 200);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetch data from Sanity
  useEffect(() => {
    client.fetch(`*[_type == "blog" && language == "${currentLanguage}"][0]`)
      .then((data) => {
        const fetchedPosts = data.posts.map(post => ({
          postImageSrc: post.postImageSrc ? urlFor(post.postImageSrc).url() : BackupImage,
          title: post.title,
          authorName: post.authorName,
          description: post.description,
          featured: post.featured || false
        }));

        setContent({
          heading: data.heading,
          subheading: data.subheading,
          description: data.description,
          posts: fetchedPosts
        });
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [currentLanguage]);

  const handlePostClick = useCallback((index) => {
    setContent(prevContent => {
      const clickedPost = prevContent.posts[index];
      const otherPosts = prevContent.posts.filter((_, i) => i !== index);

      return {
        ...prevContent,
        posts: [
          { ...clickedPost, featured: true },
          ...otherPosts.map(post => ({ ...post, featured: false }))
        ]
      };
    });
  }, []);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {content.heading && <Heading>{content.heading}</Heading>}
        </HeadingContainer>
        <Posts>
          {content.posts.map((post, index) => {
            const isSmallPost = screenWidth < 1024 ? index >= 1 : index >= 2;
            return (
              <PostContainer
                featured={post.featured}
                isSmallPost={isSmallPost}
                key={index}
                onClick={() => handlePostClick(index)}
              >
                <Post className="group">
                  <PostImage
                    as={motion.div}
                    alt={post.title}
                    transition={{ duration: 0.3 }}
                    variants={{
                      rest: { backgroundSize: post.featured ? "100%" : "110%" },
                      hover: { backgroundSize: post.featured ? "110%" : "120%" },
                    }}
                    initial="rest"
                    whileHover="hover"
                    animate="rest"
                    imageSrc={post.postImageSrc}
                  />
                  <PostText isSmallPost={isSmallPost}>
                    <PostTitle isSmallPost={isSmallPost}>{post.title}</PostTitle>
                    {!isSmallPost && post.featured && (
                      <PostDescription>{post.description}</PostDescription>
                    )}
                    {!isSmallPost && (
                      <AuthorInfo>
                        <AuthorTextInfo>
                          <AuthorName>{post.authorName}</AuthorName>
                        </AuthorTextInfo>
                      </AuthorInfo>
                    )}
                  </PostText>
                </Post>
              </PostContainer>
            );
          })}
        </Posts>
      </ContentWithPaddingXl>
    </Container>
  );
};
