import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import client from 'sanityClient';
import { useLanguage } from '../../helpers/languageSelector'; // Import the LanguageSelector context

const CookieBanner = styled.div`
  ${tw`fixed bottom-0 left-0 right-0 p-4 bg-gray-800 text-white text-center`}
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  z-index: 1000;
`;

const CookieButton = styled.button`
  ${tw`bg-primary-600 hover:bg-primary-700 text-white font-bold py-2 px-4 rounded m-2`}
`;

const Cookies = () => {
  const { currentLanguage } = useLanguage(); // Get the current language from the context
  const [isVisible, setIsVisible] = useState(false);
  const [content, setContent] = useState(null);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }

    client
      .fetch(`*[_type == "cookies" && language == $language][0]`, { language: currentLanguage })
      .then((data) => {
        setContent(data);
      })
      .catch((error) => console.error('Error fetching cookie consent content:', error));
  }, [currentLanguage]);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setIsVisible(false);
  };

  const handleDecline = () => {
    setIsVisible(false);
  };

  if (!isVisible || !content) return null;

  return (
    <CookieBanner isVisible={isVisible}>
      <p>{content.message || 'We use cookies to enhance your experience. Please accept our cookies.'}</p>
      <div>
        <CookieButton onClick={handleAccept}>
          {content.acceptButtonText || 'Accept'}
        </CookieButton>
        <CookieButton onClick={handleDecline}>
          {content.declineButtonText || 'Decline'}
        </CookieButton>
        <a
          href={content.privacyPolicyLink || '/PrivacyPolicy'}
          style={{ color: '#ffffff', textDecoration: 'underline', marginLeft: '8px' }}
        >
          {content.privacyPolicyText || 'Privacy Policy'}
        </a>
      </div>
    </CookieBanner>
  );
};

export default Cookies;
