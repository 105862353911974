import { useState } from "react";
import { useAnimation } from "framer-motion";

export default function useAnimatedNavToggler() {
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false);
  const animation = useAnimation();

  const toggleNavbar = () => {
    if (isMobileNavVisible) {
      closeNavbar();
    } else {
      animation.start({ x: 0, display: "block" });
      setIsMobileNavVisible(true);
    }
  };

  const closeNavbar = () => {
    animation.start({ x: "150%", display: "none" }).then(() => {
      setIsMobileNavVisible(false);
    });
  };

  return { isMobileNavVisible, animation, toggleNavbar, closeNavbar };
}
