import React, { useEffect, useState, useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import client from 'sanityClient';
import { urlFor } from 'sanityImageUrl';
import { useLanguage } from "../../helpers/languageSelector";
import BackupImage from '../../images/surflife/sl16.jpg'


const Container = tw.div`relative mr-6 lg:mr-0 -mt-12`;
const SingleColumn = tw.div`max-w-screen-xl mx-auto py-6 lg:py-8`;
const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`font-medium text-gray-600 text-center max-w-sm`;
const Content = tw.div`mt-4 bg-white bg-opacity-25`;
const Card = styled.div(props => [
  tw`mt-16 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);

const AspectWrapper = styled.div`
  ${tw`w-full md:w-1/2 lg:w-5/12 mx-4`}
`;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center w-full h-full`,
  props.height ? `height: ${props.height}px;` : `h-64 sm:h-48 md:h-80 lg:h-96`,
  `@media (max-width: 560px) { height: 240px; }` // Fixed shorter height for small screens
]);




// const Image = styled.div(props => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`rounded w-full md:w-1/2 lg:w-5/12 bg-cover bg-center mx-4 sm:mr-4 sm:mx-8`,
//   props.height ? `height: ${props.height}px;` : `h-64 sm:h-48 md:h-80 lg:h-96`
// ]);

const Details = tw.div`w-full md:w-1/2 lg:w-5/12 mx-4`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold`;
const Description = tw.p`mt-2 text-base leading-loose`;

export default () => {
  const { currentLanguage } = useLanguage();
  const [features, setMainFeatures] = useState({
    heading: '',
    description: '',
    features: []
  });
  const [imageHeight, setImageHeight] = useState(null);
  const detailsRef = useRef(null);

  useEffect(() => {
    client.fetch(`*[_type == "features" && language == "${currentLanguage}"][0]`)
      .then((data) => {
        setMainFeatures(data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [currentLanguage]);

  useEffect(() => {
    if (detailsRef.current) {
      setImageHeight(detailsRef.current.offsetHeight);
    }
  }, [features]); // Re-run when features data is fetched

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>{features.heading}</HeadingTitle>
          <HeadingDescription>{features.description}</HeadingDescription>
        </HeadingInfoContainer>
        <Content>
        {features.features.map((feature, i) => (
          <Card key={i} reversed={i % 2 === 1}>
            <AspectWrapper>
              <Image imageSrc={feature.image ? urlFor(feature.image).url() : BackupImage} height={imageHeight} alt={feature.title} />
            </AspectWrapper>
            <Details ref={detailsRef}>
              <Subtitle>{feature.subtitle}</Subtitle>
              <Title>{feature.title}</Title>
              <Description>{feature.description}</Description>
            </Details>
          </Card>
        ))}
        </Content>
      </SingleColumn>
    </Container>
  );
};
