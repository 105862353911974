import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { Subheading, H1Heading } from "../misc/Headings.js";
import client from 'sanityClient';
import { useLanguage } from "../../helpers/languageSelector";

const Container = tw.div`relative max-w-screen-xl mx-auto py-8 flex flex-col items-center bg-white bg-opacity-25`;
const TextContent = tw.div`text-center lg:py-4 max-w-4xl`;
// H1Heading 
const Heading = tw(H1Heading)`text-4xl sm:text-5xl lg:text-6xl font-extrabold leading-tight max-w-full`;
const NarrowDescription = tw.div`sm:mx-4 md:mx-16 lg:mx-24 mt-4 px-4 leading-loose`;
const Description = ({ text }) => (
  <NarrowDescription>
    <p
      tw="text-center leading-loose text-sm md:text-base lg:text-lg font-medium text-secondary-100 whitespace-pre-line"
      dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br />') }}
    />
  </NarrowDescription>
);

export default () => {
  const { currentLanguage } = useLanguage(); 
  const [content, setContent] = useState({ heading: '', subheading: '', description: '' });

  useEffect(() => {
    client.fetch(`*[_type == "mainFeature" && language == "${currentLanguage}"][0]`)
      .then((data) => {
        //console.log('Fetched data:', data);
        setContent(data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [currentLanguage]);

  return (
    <Container>
      <TextContent>
        <Heading>{content.heading}</Heading>
        <Subheading>{content.subheading}</Subheading>
        <Description text={content.description} />
      </TextContent>
    </Container>
  );
};
