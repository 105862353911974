import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import tw from "twin.macro";
import { ContentWithPaddingXl, Container } from "../misc/Layouts.js";
import { SectionHeading as Heading, Subheading as SubheadingBase } from "../misc/Headings.js";
import client from 'sanityClient';
import { useLanguage } from "../../helpers/languageSelector";
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

const Subheading = tw(SubheadingBase)`text-center`;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Testimonials = styled.div`
  ${tw`flex flex-col lg:flex-row items-center lg:items-stretch`}
  min-height: ${props => props.minHeight}px; /* Set minimum height dynamically */
`;

const TestimonialContainer = styled.div`
  ${tw`mt-4 lg:w-1/3 transition-opacity duration-500 ease-in-out`}
  opacity: ${props => (props.isMobile ? (props.active ? 1 : 0) : 1)};
  position: ${props => (props.isMobile ? (props.active ? "relative" : "absolute") : "static")};
  display: ${props => (props.isMobile ? (props.active ? "block" : "none") : "block")};
  animation: ${props => (props.isMobile && props.active ? fadeIn : "none")} 0.5s ease-in-out;
`;

const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex h-96 flex-col items-center`;
const Quote = tw.blockquote`mt-5 text-gray-600 bg-semi font-medium leading-loose`;
const CustomerName = tw.p`mt-5 text-gray-900 font-semibold uppercase text-sm tracking-wide`;
const Stars = tw.div`text-yellow-500 text-lg`;

const TestimonialSection = ({
  defaultSubheading = "Testimonials",
  defaultHeading = "Customer's Review",
  defaultTestimonials = [
    {
      quote:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      customerName: "Charlotte Hale"
    },
    {
      quote:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      customerName: "Adam Cuppy"
    },
    {
      quote:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      customerName: "Steven Marcetti"
    }
  ]
}) => {
  const { currentLanguage } = useLanguage();
  const [data, setData] = useState({
    subheading: '',
    heading: '',
    testimonials: []
  });
  const [currentIndex, setCurrentIndex] = useState(0); // Tracks the current testimonial index
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024); // Tracks if screen is mobile
  const [minHeight, setMinHeight] = useState(0); // Tracks the minimum height for mobile
  const containerRef = useRef(null); // Ref for calculating height

  useEffect(() => {
    client.fetch(`*[_type == "testimonial" && language == "${currentLanguage}"]`)
      .then((fetchedData) => {
        if (fetchedData.length > 0) {
          setData(fetchedData[0]);
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [currentLanguage]);

  const renderedTestimonials = data.testimonials.length > 0 ? data.testimonials : defaultTestimonials;

  // Handle screen resizing to toggle mobile view
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1024);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Set the min height based on the tallest testimonial
  useEffect(() => {
    if (containerRef.current) {
      const heights = Array.from(containerRef.current.children).map(child => child.offsetHeight);
      setMinHeight(Math.max(...heights));
    }
  }, [renderedTestimonials]);

  // Automatic slide change every 5 seconds on mobile
  useEffect(() => {
    if (isMobile) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % renderedTestimonials.length);
      }, 6000);
      return () => clearInterval(interval);
    }
  }, [isMobile, renderedTestimonials.length]);

  return (
    <Container>
      <ContentWithPaddingXl>
        {(data.subheading || defaultSubheading) && <Subheading>{data.subheading || defaultSubheading}</Subheading>}
        <Heading>{data.heading || defaultHeading}</Heading>
        <Testimonials ref={containerRef} minHeight={minHeight}>
          {renderedTestimonials.map((testimonial, index) => (
            <TestimonialContainer 
              key={index} 
              active={index === currentIndex} 
              isMobile={isMobile}
            >
              <Testimonial>
                <Stars>⭐⭐⭐⭐⭐</Stars>
                <Quote>"{testimonial.quote}"</Quote>
                <CustomerName>- {testimonial.customerName}</CustomerName>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
      </ContentWithPaddingXl>
    </Container>
  );
};

export default TestimonialSection;
