import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import { SectionHeading } from "../misc/Headings";
import { useLanguage } from "../../helpers/languageSelector"; // Make sure this is correctly imported
import client from 'sanityClient'; // Import the Sanity client


const Container = tw.div`relative -mt-12`;
const Content = tw.div`max-w-screen-xl mx-auto py-6 lg:py-8`;
const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-center`; 
const Heading = tw(SectionHeading)`pb-8 text-center`; 

export default () => {
  const { currentLanguage } = useLanguage();
  const [content, setContent] = useState({ heading: '' });

  useEffect(() => {
    // Fetch content based on the current language
    client.fetch(`*[_type == "socials" && language == "${currentLanguage}"][0]`)
      .then((data) => {
        setContent({
          heading: data.heading,
        });
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [currentLanguage]);

  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>{content.heading}</Heading>
        </HeadingWithControl>
        <div>
          {/* Behold widget for social media feed */}
          <behold-widget feed-id="w24j2UYTBcCO0ofz7CHu"></behold-widget>
          <script>
            {
              (() => {
                const d=document,s=d.createElement("script");s.type="module";
                s.src="https://w.behold.so/widget.js";d.head.append(s);
              })()
            }
          </script>
        </div>
      </Content>
    </Container>
  );
};
