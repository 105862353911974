import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "content/misc/Layouts";
import tw from "twin.macro";
import Hero from "content/components/menu.js";
import Footer from "content/components/footer.js";

const Heading = tw.h1`text-4xl font-bold text-gray-800 text-center mt-16`;
const SubHeading = tw.h2`text-lg text-gray-600 text-center mt-4`;
const ActionContainer = tw.div`flex justify-center items-center flex-col mt-8`;
const Button = tw.button`mt-4 px-6 py-2 bg-primary-500 text-white font-bold rounded hover:bg-primary-700 transition duration-300`;

export default () => {

  return (
    <AnimationRevealPage>
      <Hero />
      <Container>
        <ContentWithPaddingXl>
          <Heading>404 - Page Not Found</Heading>
          <SubHeading>Oops! The page you're looking for doesn't exist.</SubHeading>
          <ActionContainer>
            <Button onClick={() => (window.location.href = "/")}>
              Go to Homepage
            </Button>
          </ActionContainer>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
