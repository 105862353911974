import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Container } from "../misc/Layouts.js";
import { SectionHeading as Heading, Subheading as SubheadingBase } from "../misc/Headings.js";
import client from "sanityClient";
import { useLanguage } from "../../helpers/languageSelector";
import imageUrlBuilder from "@sanity/image-url";
import BackupImage from '../../images/surflife/sl16.jpg'


const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

const Subheading = tw(SubheadingBase)`text-center`;
const ContainerColor = tw.div`relative bg-alt mb-8 pt-4 -mx-8 -mt-4`;
const Highlights = tw.div`flex flex-col max-w-6xl mx-auto lg:flex-row items-center lg:items-stretch min-h-full`;

const HighlightContainer = styled.div`
  ${tw`mt-4 mb-24 lg:w-1/3 transition-opacity duration-500 ease-in-out`}
  opacity: ${(props) => (props.isMobile ? (props.active ? 1 : 0) : 1)};
  position: ${(props) => (props.isMobile ? (props.active ? "relative" : "absolute") : "static")};
  display: ${(props) => (props.isMobile ? (props.active ? "block" : "none") : "block")};
`;

const Highlight = tw.div`px-4 text-center max-w-xs mx-auto flex h-56 flex-col items-center`;
const Image = tw.img`
  w-32 h-32 rounded-full object-cover
`;
const Quote = tw.blockquote`
  mt-5 text-gray-600 font-medium leading-loose text-center h-48
`;
const CustomerName = tw.p`mt-1 text-gray-900 font-semibold uppercase text-lg tracking-wide`;

const HighlightSection = ({
  defaultSubheading = "",
  defaultHeading = "Our Highlights",
  defaultHighlights = [
    {
      imageSrc: "",
      quote:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      customerName: "Charlotte Hale",
    },
    {
      imageSrc: "", 
      quote:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      customerName: "Adam Cuppy",
    },
  ],
}) => {
  const { currentLanguage } = useLanguage();
  const [data, setData] = useState({
    subheading: "",
    heading: "",
    highlights: [],
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [minHeight, setMinHeight] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    client
      .fetch(`*[_type == "highlight" && language == "${currentLanguage}"]`)
      .then((fetchedData) => {
        if (fetchedData.length > 0) {
          const highlightsWithFixedImages = fetchedData[0].highlights.map((highlight) => ({
            ...highlight,
            imageSrc: highlight.imageSrc ? urlFor(highlight.imageSrc).url() : BackupImage,
          }));

          setData({
            ...fetchedData[0],
            highlights: highlightsWithFixedImages,
          });
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [currentLanguage]);

  const renderedHighlights =
    data.highlights.length > 0 ? data.highlights : defaultHighlights;

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1024);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const heights = Array.from(containerRef.current.children).map(
        (child) => child.offsetHeight
      );
      setMinHeight(Math.max(...heights));
    }
  }, [renderedHighlights]);

  useEffect(() => {
    if (isMobile) {
      const interval = setInterval(() => {
        setCurrentIndex(
          (prevIndex) => (prevIndex + 1) % renderedHighlights.length
        );
      }, 2500);
      return () => clearInterval(interval);
    }
  }, [isMobile, renderedHighlights.length]);

  return (
    <Container>
      <ContainerColor>
        <Heading>{data.heading || defaultHeading}</Heading>
        <Highlights ref={containerRef} minHeight={minHeight}>
          {renderedHighlights.map((highlight, index) => (
            <HighlightContainer
              key={index}
              active={index === currentIndex}
              isMobile={isMobile}
            >
              <Highlight>
                <Image alt={highlight.customerName} src={highlight.imageSrc} />
                <CustomerName>{highlight.customerName}</CustomerName>
                <Quote>{highlight.quote}</Quote>
              </Highlight>
            </HighlightContainer>
          ))}
        </Highlights>
        </ContainerColor>
    </Container>
  );
};

export default HighlightSection;
