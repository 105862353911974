import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings.js";
import { SectionDescription } from "../misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "../misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "../misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";
import client from 'sanityClient';
import { useLanguage } from "../../helpers/languageSelector"; // Import the LanguageSelector context
import { PortableText } from '@portabletext/react';

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(tw.dd`pointer-events-auto text-sm sm:text-base leading-relaxed`);

const SubheadingInAnswer = tw.div`mt-4 text-lg font-semibold text-primary-500`;
const ButtonContainer = tw.div`mt-4 flex flex-wrap`;

const Button = styled(PrimaryButtonBase)`
  ${tw`m-2`}
`;

const CloseImmediatelyButton = styled(Button)`
  ${tw`bg-red-500 hover:bg-red-700`}
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const portableTextComponents = {
  block: {
    normal: ({ children }) => <p>{children}</p>,
    h1: ({ children }) => <h1 tw="text-2xl font-bold">{children}</h1>,
    h2: ({ children }) => <h2 tw="text-xl font-bold">{children}</h2>,
    h3: ({ children }) => <h3 tw="text-lg font-bold">{children}</h3>,
    blockquote: ({ children }) => <blockquote tw="border-l-4 border-gray-300 pl-4 italic">{children}</blockquote>,
  },
  list: {
    bullet: ({ children }) => <ul tw="list-disc pl-5">{children}</ul>,
    number: ({ children }) => <ol tw="list-decimal pl-5">{children}</ol>,
  },
  marks: {
    strong: ({ children }) => <strong tw="font-bold">{children}</strong>,
    em: ({ children }) => <em tw="italic">{children}</em>,
    link: ({ children, value }) => {
      const { href } = value;
      return <a href={href} tw="text-primary-500 hover:underline">{children}</a>;
    },
  },
};

const TermsConditions = () => {
  const { currentLanguage } = useLanguage();
  const [data, setData] = useState({
    subheading: '',
    heading: '',
    description: '',
    faqs: []
  });

  useEffect(() => {
    console.log('Fetching data for language:', currentLanguage);
    client.fetch(`*[_type == "termsConditions" && language == "${currentLanguage}"]`)
      .then((fetchedData) => {
        // console.log('Fetched data:', fetchedData);
        if (fetchedData.length > 0) {
          setData(fetchedData[0]);
          // console.log('Set data:', fetchedData[0]);
        } else {
          // console.log('No data found for the current language.');
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [currentLanguage]);

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {data.subheading && <Subheading>{data.subheading}</Subheading>}
            <Heading>{data.heading}</Heading>
            {data.description && <Description>{data.description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {data.faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  <PortableText value={faq.answer} components={portableTextComponents} />
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      {/* <DecoratorBlob1/>
      <DecoratorBlob2 /> */}
    </Container>
  );
};

export default TermsConditions;
