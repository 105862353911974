import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "../misc/Layouts.js";
import logo from "../../images/surflife/Surflife.png";
import { ReactComponent as FacebookIcon } from "../../images/facebook.svg";
import { ReactComponent as LinkIcon } from "../../images/linkedin.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube.svg";
import { ReactComponent as InstaIcon } from "../../images/insta-icon.svg";
import LanguageSelector, { useLanguage, pageMappings } from "../../helpers/languageSelector"; // Import the useLanguage hook
import client from 'sanityClient'; // Import the Sanity client
import { NavLink } from "../headers/light.js";



const Container = tw(ContainerBase)`bg-alt text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-6 lg:py-8`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-32`;
// const LogoText = tw.h5`ml-2 text-2xl text-primary-500 font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 text-gray-500 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.button`border-b-2 border-transparent hocus:text-gray-700 hocus:border-gray-700 pb-1 transition duration-300 mt-2 mx-4 text-left`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-500 hover:text-gray-700 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const currentYear = new Date().getFullYear();

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;

const Footer = () => {
  const { currentLanguage } = useLanguage();
  const [footerContent, setFooterContent] = useState({
    insurance: '',
    privacyPolicy: '',
    termsConditions: '',
    crew: '',
    groups: '',
  });

  useEffect(() => {
    // Fetch footer content based on the current language
    client.fetch(`*[_type == "footer" && language == "${currentLanguage}"][0]`)
      .then((data) => {
        setFooterContent({
          insurance: data.insurance,
          privacyPolicy: data.privacyPolicy,
          termsConditions: data.termsConditions,
          information: data.information,
          crew: data.crew,
          groups: data.groups,
        });
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [currentLanguage]);

  return (
    <Container>
      <Content>
        <Row>
        <LogoContainer>
            <a
              href="/"
              onClick={() => {
                // Remove scroll position from localStorage
                localStorage.removeItem("savedSection");
              }}
            >
              <LogoImg src={logo} alt="Surflife Logo" />
            </a>
          </LogoContainer>
          <LinksContainer>
  <NavLink href={pageMappings["/insurance"][currentLanguage]}>{footerContent.insurance}</NavLink>
  <NavLink href={pageMappings["/privacyPolicy"][currentLanguage]}>{footerContent.privacyPolicy}</NavLink>
  <NavLink href={pageMappings["/termsAndCondition"][currentLanguage]}>{footerContent.termsConditions}</NavLink>
  <NavLink href={pageMappings["/crew"][currentLanguage]}>{footerContent.crew}</NavLink>
  <NavLink href={pageMappings["/groups"][currentLanguage]}>{footerContent.groups}</NavLink>
  <NavLink href={pageMappings["/information"][currentLanguage]}>{footerContent.information}</NavLink>
  <LanguageSelector />
</LinksContainer>

          <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/Surflife1991/" target="_blank" rel="noopener noreferrer">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://www.linkedin.com/company/surflife-nl-b-v-/" target="_blank" rel="noopener noreferrer">
              <LinkIcon />
            </SocialLink>
            <SocialLink href="https://www.youtube.com/@SURFLIFENL" target="_blank" rel="noopener noreferrer">
              <YoutubeIcon />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/surflifetrips/" target="_blank" rel="noopener noreferrer">
              <InstaIcon />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            &copy; Copyright {currentYear}, Surflife. All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};

export default Footer;
