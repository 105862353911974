
const handleNavClick = (id) => {
  console.log(`Navigating to ${id}`);

  // Save the section in localStorage
  localStorage.setItem("savedSection", `#${id}`);

  // Get the current language from the path (e.g., "/nl", "/en", "/de")
  const currentPath = window.location.pathname;
  const langPrefix = currentPath.startsWith('/nl') || currentPath.startsWith('/en') || currentPath.startsWith('/de')
    ? currentPath.split('/')[1]
    : null;

  // Reload the page with the correct #section in the URL
  const newPath = langPrefix ? `/${langPrefix}#${id}` : `/#${id}`;
  window.location.href = newPath;
};

export default handleNavClick;
