import React, { createContext, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as ChevronUpIcon } from "feather-icons/dist/icons/chevron-up.svg";
import { ReactComponent as FlagEN } from "images/flags/en.svg";
import { ReactComponent as FlagNL } from "images/flags/nl.svg";
import { ReactComponent as FlagGE } from "images/flags/ge.svg";

// Create the context
const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState(null);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    const isInternalNavigation = localStorage.getItem("isInternalNavigation");
    const pathname = window.location.pathname;

    const initializeLanguage = () => {
      // Detect language from `pageMappings` if directly accessing a URL
      const languageFromPath = Object.keys(pageMappings).find((key) =>
        Object.values(pageMappings[key]).includes(pathname)
      );

      if (languageFromPath && !isInternalNavigation) {
        const detectedLanguage = Object.keys(pageMappings[languageFromPath]).find(
          (lang) => pageMappings[languageFromPath][lang] === pathname
        );

        if (detectedLanguage) {
          setCurrentLanguage(detectedLanguage);
          localStorage.setItem("language", detectedLanguage);
          return;
        }
      }

      // Use stored language if no explicit match in the URL
      if (storedLanguage) {
        setCurrentLanguage(storedLanguage);
      } else {
        // Fallback to IP-based detection
        axios
          .get("https://ipinfo.io?token=cddec610ac4e43")
          .then(({ data }) => {
            const { country } = data;
            let defaultLanguage = "en";
            if (country === "NL") defaultLanguage = "nl";
            if (country === "DE") defaultLanguage = "de";
            setCurrentLanguage(defaultLanguage);
            localStorage.setItem("language", defaultLanguage);
          })
          .catch((error) => {
            console.error("Error detecting location:", error);
            setCurrentLanguage("en"); // Default to English
          });
      }

      // Clear the `isInternalNavigation` flag after initialization
      localStorage.removeItem("isInternalNavigation");
    };

    initializeLanguage();
  }, []);

  const updateLanguage = (language) => {
    setCurrentLanguage(language);
    localStorage.setItem("language", language);
  };

  if (!currentLanguage) {
    return <div>Loading...</div>;
  }

  return (
    <LanguageContext.Provider value={{ currentLanguage, setCurrentLanguage: updateLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};




// useLanguage hook
export const useLanguage = () => useContext(LanguageContext);

// LanguageSelector component
const Container = tw.div`relative ml-4 inline-block text-left`;
const Trigger = styled.button`
  ${tw`flex items-center justify-between rounded-md text-sm font-medium text-gray-700 hover:text-gray-900 focus:outline-none`}
  width: 48px;
  height: 18px;
  margin-bottom: 10px;
  svg {
    ${tw`w-5 h-5`}
    transform: translateY(3px);
  }
`;

const FlagContainer = styled.div`
  ${tw`flex items-center`}
  transform: translateY(2px);
`;

const Menu = tw.div`absolute left-0 mt-1 w-32 rounded-md z-10 shadow-lg bg-white ring-1 ring-black ring-opacity-5`;
const MenuItem = styled.div`
  ${tw`flex items-center px-4 py-2 text-sm cursor-pointer hover:bg-gray-100`}
  svg {
    ${tw`w-5 h-5 mr-2`}
  }
`;

export const pageMappings = {
  "/nl": { nl: "/nl", en: "/en", de: "/de" },
  "/voorwaarden": { nl: "/voorwaarden", en: "/termsAndCondition", de: "/geschaftsbedingungen" },
  "/verzekering": { nl: "/verzekering", en: "/insurance", de: "/Versicherung" },
  "/informatie": { nl: "/informatie", en: "/information", de: "/informationen" },
  "/groepsreizen": { nl: "/groepsreizen", en: "/groups", de: "/gruppe" },
  "/crew-nl": { nl: "/crew-nl", en: "/crew", de: "/crew-de" },
  "/en": { nl: "/nl", en: "/en", de: "/de" },
  "/termsAndCondition": { nl: "/voorwaarden", en: "/termsAndCondition", de: "/geschaftsbedingungen" },
  "/privacyPolicy": { nl: "/privacybeleid", en: "/privacyPolicy", de: "/datenschutz" },
  "/insurance": { nl: "/verzekering", en: "/insurance", de: "/Versicherung" },
  "/information": { nl: "/informatie", en: "/information", de: "/informationen" },
  "/groups": { nl: "/groepsreizen", en: "/groups", de: "/gruppe" },
  "/crew": { nl: "/crew-nl", en: "/crew", de: "/crew-de" },
  "/de": { nl: "/nl", en: "/en", de: "/de" },
  "/geschaftsbedingungen": { nl: "/voorwaarden", en: "/termsAndCondition", de: "/geschaftsbedingungen" },
  "/datenschutz": { nl: "/privacyPolicy", en: "/privacyPolicy", de: "/datenschutz" },
  "/Versicherung": { nl: "/verzekering", en: "/insurance", de: "/Versicherung" },
  "/informationen": { nl: "/informatie", en: "/information", de: "/informationen" },
  "/gruppe": { nl: "/groepsreizen", en: "/groups", de: "/gruppe" },
  "/crew-de": { nl: "/crew-nl", en: "/crew", de: "/crew-de" },
};

const LanguageSelector = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { currentLanguage, setCurrentLanguage } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();

  const languages = [
    { code: "en", name: "English", flag: <FlagEN /> },
    { code: "nl", name: "Dutch", flag: <FlagNL /> },
    { code: "de", name: "German", flag: <FlagGE /> },
  ];

  useEffect(() => {
    const currentPath = location.pathname;

    // Detect language from the URL if directly accessed
    const languageFromPath = Object.keys(pageMappings).find((key) =>
      Object.values(pageMappings[key]).includes(currentPath)
    );

    if (languageFromPath) {
      const detectedLanguage = Object.keys(pageMappings[languageFromPath]).find(
        (lang) => pageMappings[languageFromPath][lang] === currentPath
      );

      if (detectedLanguage && detectedLanguage !== currentLanguage) {
        setCurrentLanguage(detectedLanguage);
        localStorage.setItem("language", detectedLanguage);
        localStorage.removeItem("isInternalNavigation"); // Clear the flag for external navigation
      }
    }
  }, [location.pathname, currentLanguage, setCurrentLanguage]);

  const handleLanguageSelect = (newLanguage) => {
    const currentPath = location.pathname;
  
    // Map the path for the selected language
    const mappedPath = pageMappings[currentPath]?.[newLanguage] || `/${newLanguage}`;
    setCurrentLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
    localStorage.setItem("isInternalNavigation", "true"); // Mark as internal navigation
    navigate(mappedPath);
    setIsOpen(false);
  };

  return (
    <Container>
      <Trigger onClick={() => setIsOpen(!isOpen)}>
        <FlagContainer>{languages.find((lang) => lang.code === currentLanguage)?.flag}</FlagContainer>
        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </Trigger>
      {isOpen && (
        <Menu>
          {languages.map((lang) => (
            <MenuItem key={lang.code} onClick={() => handleLanguageSelect(lang.code)}>
              {lang.flag}
              {lang.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Container>
  );
};

export default LanguageSelector;



