import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "content/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Hero from "content/components/menu.js";
import Footer from "content/components/footer.js";
import { SectionHeading } from "content/misc/Headings";
import ContactUsForm from "content/components/contact.js";
import client from 'sanityClient';
import { useLanguage } from "../helpers/languageSelector"; // Import the LanguageSelector context
import { PortableText } from '@portabletext/react';
import { urlFor } from 'sanityImageUrl'; // Correct import path
import BackupImage from '../images/surflife/sl16.jpg'

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`mb-10`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-6 md:py-8`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-8 mb-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8`;

const portableTextComponents = {
  block: {
    normal: ({ children }) => <p>{children}</p>,
    h1: ({ children }) => <h1>{children}</h1>,
    h2: ({ children }) => <h2>{children}</h2>,
    h3: ({ children }) => <h3>{children}</h3>,
    blockquote: ({ children }) => <blockquote>{children}</blockquote>,
  },
  list: {
    bullet: ({ children }) => <ul>{children}</ul>,
    number: ({ children }) => <ol>{children}</ol>,
  },
  marks: {
    strong: ({ children }) => <strong>{children}</strong>,
    em: ({ children }) => <em>{children}</em>,
    link: ({ children, value }) => <a href={value.href}>{children}</a>,
  },
};

export default ({ textOnLeft = false }) => {
  const { currentLanguage } = useLanguage();
  const [infoData, setInfoData] = useState({ headingText: '', image: null });

  useEffect(() => {
    client.fetch(`*[_type == "info" && language == "${currentLanguage}"][0]`)
      .then((data) => setInfoData(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, [currentLanguage]);

  if (!infoData) {
    return <div>Loading...</div>;
  }

  return (
    <AnimationRevealPage>
      <Hero />
      <Container>
        <TwoColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              <HeadingRow>
                <Heading>{infoData.headingText}</Heading>
              </HeadingRow>
              <PortableText value={infoData.content} components={portableTextComponents} />
            </TextContent>
          </TextColumn>
          <ImageColumn>
            <Image
              imageSrc={infoData.image ? urlFor(infoData.image).url() : BackupImage}
            />
          </ImageColumn>
        </TwoColumn>
        <ContactUsForm />
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
