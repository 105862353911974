import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "../misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings";
import { SectionDescription } from "../misc/Typography";
import client from 'sanityClient';
import { useLanguage } from "../../helpers/languageSelector";
import { urlFor } from 'sanityImageUrl';
import BackupImage from '../../images/surflife/sl16.jpg';

const HeadingContainer = tw.div`-mt-8`;
const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`mx-auto max-w-3xl bg-semi text-secondary-300 px-4 text-center`;
const DescriptionPicture = tw(SectionDescription)`mx-auto bg-semi text-secondary-700 px-4 text-base leading-snug text-center font-normal`;

const BigImageContainer = styled.div`
  ${tw`flex justify-center items-start space-x-4 mb-8`}
  @media (max-width: 768px) {
    ${tw`space-x-0`}
  }
`;

const BigImage = styled.div`
  ${props => css`
    background-image: url("${props.imageSrc}");
  `}
  ${tw`w-64 h-64 mt-4 mx-auto bg-cover bg-center rounded-lg flex-shrink-0`}
  transition: background-image 0.3s ease-in-out;

  &:hover {
    ${props => css`
      background-image: url("${props.hoverImageSrc}");
    `}
  }
`;

const ExpandedText = styled.div`
  ${tw`bg-semi text-xl text-secondary-300 text-center my-8 mx-40`}
`;

const CenterImage = styled.div`
  ${props => css`
    background-image: url("${props.src}");
  `}
  ${tw`w-1/2 h-96 mt-4 mx-auto bg-cover bg-center rounded-lg`}
`;

const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  }
`;

const SmallImagesWrapper = tw.div`relative overflow-x-auto w-full h-32`;
const SmallImagesContainer = tw.div`flex justify-start md:justify-center space-x-4 px-4`;

const CustomContainer = styled(Container)`
  ${tw`p-0 -mx-8`}
`;

const SmallImage = styled.div`
  ${props => css`
    background-image: url("${props.imageSrc}");
  `}
  ${tw`w-24 h-24 bg-cover bg-center rounded-full flex-shrink-0 border border-gray-300 cursor-pointer`}
`;

const Button = tw.button`mt-12 px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 transition duration-300 mx-auto block`;

const CenteredText = tw.div`text-center px-4 mt-4 mx-auto`;

const TeamSection = () => {
  const { currentLanguage } = useLanguage();
  const [content, setContent] = useState({
    heading: '',
    subheading: '',
    description: '',
    members: []
  });
  const [expanded, setExpanded] = useState(false);
  const [currentBigImages, setCurrentBigImages] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    client.fetch(`*[_type == "team" && language == "${currentLanguage}"][0]`)
      .then((data) => {
        const members = data.members.map(member => ({
          imageSrc: member.imageSrc ? urlFor(member.imageSrc).url() : BackupImage,
          hoverImageSrc: member.hoverImageSrc ? urlFor(member.hoverImageSrc).url() : BackupImage,
          position: member.position,
          name: member.name,
          description: member.description
        }));
  
        setContent({
          heading: data.heading,
          subheading: data.subheading,
          description: data.description,
          more: data.more,
          less: data.less,
          expandedText: data.expandedText,
          centerImage: data.centerImage,
          members: members
        });
  
        // Sequential big image loading
        let bigImages = [];
        members.slice(0, 3).forEach((member, index) => {
          setTimeout(() => {
            bigImages = [...bigImages, member];
            setCurrentBigImages([...bigImages]); // Update state with new big image
          }, index * 500); // Delay between image loads
        });
  
        // Preload smaller images after big images
        setTimeout(() => {
          members.forEach(member => {
            const img = new Image();
            img.src = member.imageSrc;
          });
        }, members.slice(0, 3).length * 500); // Starts after big images have loaded
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [currentLanguage]);
  

  const handleSmallImageClick = (index) => {
    const newBigImages = content.members.slice(index, index + 3);
    setCurrentBigImages(newBigImages.length < 3
      ? [...newBigImages, ...content.members.slice(0, 3 - newBigImages.length)]
      : newBigImages);
  };

  return (
    <CustomContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {content.subheading && <Subheading>{content.subheading}</Subheading>}
          {content.heading && <Heading>{content.heading}</Heading>}
          {content.description && <Description>{content.description}</Description>}
        </HeadingContainer>

        <BigImageContainer>
          {isMobile
            ? currentBigImages.slice(0, 1).map((card, index) => (
                <div key={index}>
                  <BigImage alt='Surflife_Crew' imageSrc={card.imageSrc} hoverImageSrc={card.hoverImageSrc} />
                  <CardContent>
                    <span className="position">{card.position}</span>
                    <span className="name">{card.name}</span>
                    <DescriptionPicture>{card.description}</DescriptionPicture>
                  </CardContent>
                </div>
              ))
            : currentBigImages.map((card, index) => (
                <div key={index}>
                  <BigImage alt='Surflife_Crew' imageSrc={card.imageSrc} hoverImageSrc={card.hoverImageSrc} />
                  <CardContent>
                    <span className="position">{card.position}</span>
                    <span className="name">{card.name}</span>
                    <DescriptionPicture>{card.description}</DescriptionPicture>
                  </CardContent>
                </div>
              ))}
        </BigImageContainer>

        <SmallImagesWrapper>
          <SmallImagesContainer>
            {content.members.map((card, index) => (
              <SmallImage
                alt='Surflife_Crew_Small'
                key={index}
                imageSrc={card.imageSrc}
                onClick={() => handleSmallImageClick(index)}
              />
            ))}
          </SmallImagesContainer>
        </SmallImagesWrapper>

        <div className="text-center">
          <Button onClick={() => setExpanded(!expanded)}>
            {expanded ? content.less : content.more}
          </Button>
        </div>

        {expanded && (
          <CenteredText>
            <ExpandedText>{content.expandedText}</ExpandedText>
            {/* <CenterImage src={urlFor(content.centerImage).url()} /> */}
          </CenteredText>
        )}
      </ContentWithPaddingXl>
    </CustomContainer>
  );
};

export default TeamSection;
