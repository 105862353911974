import React, { useEffect, useState, useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import YouTube from "react-youtube";
import sl17Image from 'images/surflife/sl7.jpg';
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate from react-router-dom
import handleNavClick from "../../helpers/useSmoothScroll";
import useScrollToSavedSection from "../../helpers/useScrollToSavedSection";
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import LanguageSelector, { useLanguage, pageMappings } from "../../helpers/languageSelector"; // Import the useLanguage hook
import client from 'sanityClient';
import Header, { NavLink, NavLinks, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import { urlFor } from 'sanityImageUrl';

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-700 hover:border-gray-900 hover:text-gray-900`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-700 hover:text-primary-900`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-12 z-20 bg-white bg-center bg-cover`}
  background-position: top center;
  background-size: cover;

  &::before, &::after {
    ${tw`absolute inset-x-0 bg-white`}
    content: '';
    z-index: 0;
  }

  &::before {
    ${tw`h-24 top-0`}
  }
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-300 opacity-5`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;

const PrimaryAction = styled.a`
  ${tw`px-6 cursor-pointer py-2 text-sm sm:text-base ml-10 mt-2 mb-4 sm:px-6 sm:py-2 bg-primary-600 text-gray-300 font-bold shadow transition duration-300 hocus:bg-primary-700 hocus:text-gray-100 focus:shadow-outline`}
  display: inline-block;
  position: relative;
  left: -20px;
`;

const VideoWrapper = styled.div`
  position: relative;
  background: transparent; /* Ensure no background color shows */
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${tw`bg-transparent shadow-xl`}
    border: none;
  }
`;

const MuteButton = styled.button`
  ${tw`absolute bottom-[1rem] left-[1rem] px-4 py-2 opacity-75 text-sm font-bold rounded hover:opacity-100 focus:outline-none focus:ring`}
  z-index: 10;
`;

const FullscreenButton = styled.button`
  ${tw`absolute bottom-[1rem] right-[1rem] text-white px-4 py-2 opacity-75 text-sm font-bold rounded hover:opacity-100 focus:outline-none focus:ring`}
  z-index: 10;
`;

export default () => {
  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const { currentLanguage } = useLanguage();
  const [content, setContent] = useState({
    ourStory: '', pricing: '', faq: '', contact: '', notification: '', heading: '', description: '', heroImage: null, bookLink: '', bookText: 'BOOK', videoUrl: ''
  });
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [player, setPlayer] = useState(null); // Store YouTube player instance
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();


  const toggleMute = () => {
    if (player) {
      if (isMuted) {
        player.unMute();
      } else {
        player.mute();
      }
      setIsMuted(!isMuted);
    }
  };

  const toggleFullscreen = () => {
    if (player) {
      const iframe = player.getIframe();
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.webkitRequestFullscreen) {
        iframe.webkitRequestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        iframe.mozRequestFullScreen();
      } else if (iframe.msRequestFullscreen) {
        iframe.msRequestFullscreen();
      }
    }
  };

  const onYouTubeReady = (event) => {
    setPlayer(event.target);
    event.target.mute();
  };

  const videoOptions = {
    playerVars: {
      autoplay: 1,
      mute: 1,
      loop: 1,
      playlist: content.videoUrl?.split("v=")[1] || "2EhO6shXVo4",
      controls: 0,
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
      fs: 1,
    },
  };

  useEffect(() => {
    client.fetch(`*[_type == "menu" && language == "${currentLanguage}"][0]`)
      .then((data) => {
        setContent({
          meetTeam: data.meetTeam,
          pricing: data.pricing,
          faq: data.faq,
          group: data.group,
          contact: data.contact,
          notification: data.notification,
          heading: data.heading,
          description: data.description,
          heroImage: data.heroImage,
          bookLink: data.bookLink,
          bookText: data.bookText,
          videoUrl: data.videoUrl
        });
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [currentLanguage]);

  useEffect(() => {
    const videoDelay = setTimeout(() => {
      setVideoLoaded(true);
    }, 1000);

    return () => clearTimeout(videoDelay);
  }, []);

  useEffect(() => {
    if (!player) return;
  
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          player.pauseVideo(); // Pause video when it's out of view
        } else {
          player.playVideo(); // Resume video when it comes back into view
        }
      },
      {
        threshold: 0.5, // Trigger when at least 50% of the video is visible
      }
    );
  
    if (videoRef.current) {
      observer.observe(videoRef.current);
    }
  
    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [player]);

  useScrollToSavedSection(); // Automatically scroll to the saved section on page load
  
  const navLinks = [
    <NavLinks key={1}>
      <NavLink onClick={() => handleNavClick("crew")}>{content.meetTeam}</NavLink>
      <NavLink onClick={() => handleNavClick("pricing")}>{content.pricing}</NavLink>
      <NavLink onClick={() => handleNavClick("faq")}>{content.faq}</NavLink>
      <NavLink href={pageMappings["/groups"][currentLanguage]}>{content.group}</NavLink>
      <NavLink onClick={() => handleNavClick("contact")}>{content.contact}</NavLink>
      <LanguageSelector />
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryAction href={content.bookLink} target="_blank" rel="noopener noreferrer">
        {content.bookText}
      </PrimaryAction>
    </NavLinks>
  ];

  const subPages = [
    '/groups',
    '/insurance',
    '/crew',
    '/information',
    '/termsAndCondition',
    '/privacyPolicy',
    '/voorwaarden',
    '/terms-condition',
    '/privacy-policy',
    '/verzekering',
    '/contact',
    '/groepsreizen',
    '/group-travel',
    '/crew2024',
    '/animator',
    '/crew-nl',
    '/informatie',
    '/crew-de',
    '/Versicherung',
    '/datenschutz',
    '/gruppe',
    '/informationen',
    '/geschaftsbedingungen'
  ];


  //const shouldDisplayVideo = subPagesInc.some(subPagesInc => window.location.pathname.includes(subPagesInc));
  const shouldDisplayVideo = !subPages.some(subPage => window.location.pathname.includes(subPage));

  return (
    <Container bgImage={content.heroImage ? urlFor(content.heroImage).url() : sl17Image}>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks}>
        <LogoLink
            onClick={() => {
              // Remove scroll position from localStorage.
              localStorage.removeItem("savedSection");

              // Retrieve the stored language or default to 'nl'.
              const storedLanguage = localStorage.getItem("language") || "nl";

              // Navigate to the homepage with the selected language.
              navigate(`/${storedLanguage}`);
            }}
          >
          </LogoLink>

        </StyledHeader>
      </HeroContainer>
      {videoLoaded && shouldDisplayVideo && (
        <VideoWrapper ref={videoRef}>
          <YouTube
            videoId={content.videoUrl?.split("v=")[1] || "JPwAsORGhQ8"}
            opts={videoOptions}
            onReady={onYouTubeReady}
          />
          <MuteButton onClick={toggleMute}>
            {isMuted ? '🔇' : '🔊'}
          </MuteButton>
          <FullscreenButton onClick={toggleFullscreen}>
            ⛶
          </FullscreenButton>
        </VideoWrapper>
      )}
    </Container>
  );
};
