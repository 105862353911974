import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { css } from "styled-components/macro"; //eslint-disable-line


import NotFoundPage from "pages/404.js"; // Import the 404 page

import TermsOfServicePage from "pages/TermsOfService.js";
import PrivacyPolicyPage from "pages/PrivacyPolicy.js";
import Insurance from "pages/Insurance.js";
import Information from "pages/Info.js";
import Groups from "pages/Group.js";
import Crew from "pages/Crew.js";
import SurfLifeLandingPage from "content/SurfLifeLandingPage";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { LanguageProvider } from "helpers/languageSelector";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;


  return (
    <>
      <GlobalStyles />
      <LanguageProvider>
      <Router>
      <Routes>
      <Route path="/" element={<SurfLifeLandingPage />} />
      {/* nl */}
      <Route path="/nl" element={<SurfLifeLandingPage />} />
      <Route path="/voorwaarden" element={<TermsOfServicePage />} />
      <Route path="/verzekering" element={<Insurance />} />
      <Route path="/informatie" element={<Information />} />
      <Route path="/groepsreizen" element={<Groups />} />
      <Route path="/crew-nl" element={<Crew/>} />
      
      {/* en */}
      <Route path="/en" element={<SurfLifeLandingPage />} />
      <Route path="/termsAndCondition" element={<TermsOfServicePage />} />
      <Route path="/privacyPolicy" element={<PrivacyPolicyPage />} />
      <Route path="/insurance" element={<Insurance />} />
      <Route path="/information" element={<Information />} />
      <Route path="/groups" element={<Groups/>} />
      <Route path="/crew" element={<Crew/>} />

      {/* de */}
      <Route path="/de" element={<SurfLifeLandingPage />} />
      <Route path="/geschaftsbedingungen" element={<TermsOfServicePage />} />
      <Route path="/datenschutz" element={<PrivacyPolicyPage />} />
      <Route path="/Versicherung" element={<Insurance/>} />
      <Route path="/informationen" element={<Information />} />
      <Route path="/gruppe" element={<Groups/>} />
      <Route path="/crew-de" element={<Crew/>} />
    
      
      { /* extra */}
      <Route path="/terms-conditions" element={<TermsOfServicePage />} />
      <Route path="/contact" element={<Information />} />
      <Route path="/home" element={<SurfLifeLandingPage />} />
      <Route path="/surf-camps" element={<SurfLifeLandingPage />} />
      <Route path="/surflife-founders" element={<SurfLifeLandingPage  />} />
      <Route path="/group-travel" element={<Groups/>} />
      <Route path="*" element={<NotFoundPage />} />
      </Routes>
      </Router>
      </LanguageProvider>
    </>
  );
}