import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "content/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Hero from "content/components/menu.js";
import Footer from "content/components/footer.js";
import { SectionHeading } from "content/misc/Headings";
import client from 'sanityClient';
import { PortableText } from '@portabletext/react';
import { useLanguage } from "../helpers/languageSelector"; // Import the LanguageSelector context

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`mb-10`;
const Text = styled.div`
  ${tw`text-lg text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const portableTextComponents = {
  block: {
    normal: ({ children }) => <p>{children}</p>,
    h1: ({ children }) => <h1 tw="text-3xl font-bold mt-10">{children}</h1>,
    h2: ({ children }) => <h2 tw="text-2xl font-bold mt-8">{children}</h2>,
    h3: ({ children }) => <h3 tw="text-xl font-bold mt-6">{children}</h3>,
    blockquote: ({ children }) => <blockquote tw="border-l-4 border-gray-300 pl-4 italic">{children}</blockquote>,
  },
  list: {
    bullet: ({ children }) => <ul tw="list-disc list-inside">{children}</ul>,
    number: ({ children }) => <ol tw="list-decimal list-inside">{children}</ol>,
  },
  marks: {
    strong: ({ children }) => <strong tw="font-bold">{children}</strong>,
    em: ({ children }) => <em tw="italic">{children}</em>,
    link: ({ children, value }) => <a href={value.href} tw="text-blue-500 underline">{children}</a>,
  },
};

export default () => {
  const { currentLanguage } = useLanguage();
  const [privacyPolicy, setPrivacyPolicy] = useState(null);

  useEffect(() => {
    client.fetch(`*[_type == "privacyPolicy" && language == "${currentLanguage}"][0]`)
      .then((data) => setPrivacyPolicy(data))
      .catch((error) => console.error('Error fetching privacy policy:', error));
  }, [currentLanguage]);

  if (!privacyPolicy) {
    return <div>Loading...</div>;
  }

  return (
    <AnimationRevealPage>
      <Hero />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{privacyPolicy.headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>{privacyPolicy.lastUpdatedText}: {new Date(privacyPolicy.lastUpdatedDate).toLocaleDateString()}</p>
            <PortableText value={privacyPolicy.content} components={portableTextComponents} />
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
