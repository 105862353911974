import imageUrlBuilder from '@sanity/image-url';
import client from './sanityClient';

const builder = imageUrlBuilder(client);

export function urlFor(source) {
  return builder.image(source)
    .auto('format')   // Use WebP if supported
    .fit('max')       // Maintain aspect ratio while resizing
    .quality(20);     // Compress the image with 30% quality (adjustable)
}
